import { EventEmitter, Injectable } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'

import { Observable } from 'rxjs'

const LANGUAGE_SEPARATOR = '-'

@Injectable({
  providedIn: 'root',
})
export class TranslatorService {
  onLangChange: EventEmitter<LangChangeEvent>

  constructor(private translateService: TranslateService) {
    this.onLangChange = this.translateService.onLangChange
    this.onLangChange.subscribe(({ lang: _lang }) => localStorage.setItem('currentLang', _lang))
  }

  getCurrentLang(localeFormat = false): string {
    const lang = this.translateService.currentLang
    return !localeFormat ? lang : `${lang}${LANGUAGE_SEPARATOR}${lang.toUpperCase()}`
  }

  getLanguages(): Array<string> {
    return this.translateService.getLangs()
  }

  use(lang: string, localeFormat = false): Observable<any> {
    return this.translateService.use(localeFormat ? lang.split(LANGUAGE_SEPARATOR)[0] : lang)
  }

  stream(
    key: string | Array<string>,
    interpolateParams?: NonNullable<unknown>,
  ): Observable<string | any> {
    return this.translateService.stream(key, interpolateParams)
  }

  get(
    key: string | Array<string>,
    interpolateParams?: NonNullable<unknown>,
  ): Observable<string | any> {
    return this.translateService.get(key, interpolateParams)
  }

  instant(key: string | Array<string>, interpolateParams?: NonNullable<unknown>): string | any {
    return this.translateService.instant(key, interpolateParams)
  }
}
