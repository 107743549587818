import { Component } from '@angular/core'
import {environment} from "@env";

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ApplicationsComponent {
  readonly buildVersion = environment.BUILD_VERSION
}
