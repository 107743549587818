import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { ButtonComponent, HeadingComponent, MainLogoComponent } from './components'
import { AnalyticsService } from './services/analytics.service'
import { ExternalRedirectComponent } from '@shared/components/external-redirect/external-redirect.component'
import { DefaultImageModule, SafePipeModule, ToastModule } from '@mediacoach/ui'

@NgModule({
  declarations: [ButtonComponent, HeadingComponent, MainLogoComponent, ExternalRedirectComponent],
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    SafePipeModule,
    DefaultImageModule,
    ToastModule,
  ],
  exports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ButtonComponent,
    HeadingComponent,
    MainLogoComponent,
    ExternalRedirectComponent,
    SafePipeModule,
    DefaultImageModule,
    ToastModule,
  ],
  providers: [AnalyticsService],
})
export class SharedModule {}
