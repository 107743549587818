import { Injectable } from '@angular/core'

declare const ga: any

@Injectable()
export class AnalyticsService {
  trackUser(infoUser: string) {
    ga('set', 'userId', infoUser)
    ga('send', 'pageview')
  }

  trackEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null,
  ) {
    ga('send', 'event', {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
    })
  }
}
