import { AuthPages } from '@core/services/auth/auth.models'

export enum ErrorCode {
  Forbidden = 403,
  NotFound = 404,
}

export const PAGE_MAPPING = {
  [AuthPages.NotPermitted]: {
    title: ErrorCode.Forbidden.toString(),
    subTitle: 'MTR_SUBTITLE_FORBIDDEN',
  },
  default: {
    title: ErrorCode.NotFound.toString(),
    subTitle: 'MTR_SUBTITLE_NOT_FOUND',
  },
}
