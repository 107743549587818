import { APP_INITIALIZER, NgModule } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { SharedModule } from '@shared/shared.module'
import { AlertsInterceptor } from './interceptors/alerts.interceptor'
import { HeaderComponent } from './components/header/header.component'
import { initLanguages } from '@core/factories/i18n.factory'
import { TranslateService } from '@ngx-translate/core'

@NgModule({
  declarations: [HeaderComponent],
  imports: [SharedModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AlertsInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLanguages,
      deps: [TranslateService],
      multi: true,
    },
  ],
  exports: [HeaderComponent],
})
export class CoreModule {}
