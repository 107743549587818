<div class="p-grid p-justify-center">
  <div class="p-col-12 u-margin-bottom--lg">
    <app-heading titleText="MTR_TITLE_APPLICATIONS"></app-heading>
  </div>
  <div class="p-lg-6 c-applications__container">
    <div class="c-applications__wrapper">
      <ng-template ngFor let-item [ngForOf]="thirdPartyApplications">
        <div class="c-applications__item p-align-center">
          <a [href]="item.link | safe: 'resourceUrl'">
            <img
              [srcUrl]="item.imgFile"
              [class.c-applications__item--shadow]="item.hasShadow"
              [mcuiDefaultImage]="DefaultMenuAvatar"
              [title]="item.name || ''"
            />
          </a>
        </div>
      </ng-template>
    </div>
  </div>
</div>
