import { TranslateService } from '@ngx-translate/core'
import { environment } from '@env'

// nasty trick to get both languages running properly
export const initLanguages = (translate: TranslateService) => () => {
  const langs = Object.keys(environment.I18N_LANGS)
  translate.addLangs(langs)
  langs.forEach((l) => {
    translate.use(l)
  })
  translate.setDefaultLang(langs[0])
}
