interface DateParams {
  day?: number
  month?: number
  year?: number
  offset?: number
}

export const cloneObject = (obj) => {
  const clone = {}
  Object.keys(obj).forEach(function (key) {
    clone[key] =
      obj[key] != null && typeof obj[key] === 'object' && !Array.isArray(obj[key])
        ? cloneObject(obj[key])
        : obj[key]
  })
  return clone
}

export const setISODate = (date) => {
  if (date) {
    date.setUTCMinutes(-date.getTimezoneOffset())
  }
  return date
}

export const now = new Date()
export const getDate = (dateParams: DateParams = <DateParams>{}) => {
  const { day, year, month, offset } = dateParams
  const date = new Date(year || now.getFullYear(), month || now.getMonth(), day || now.getDate())
  if (offset) {
    date.setDate(date.getDate() + offset)
  }
  return date
}
export const nowDate = getDate()

export const clearStorageByPrefix = (storage, prefix) =>
  Array(storage.length)
    .fill(1)
    .map((i) => localStorage.key(i))
    .filter((key) => key.indexOf(prefix) === 0)
    .forEach((key) => localStorage.removeItem(key))
