import { InjectionToken } from '@angular/core'
import { Routes } from '@angular/router'

import {
  ApplicationsComponent,
  CallbackComponent,
  ErrorPageComponent,
  LoginComponent,
  LogoutComponent,
} from '@pages/components'

import { validApplicationGuard } from '@core/guards/valid-application-guard.service'
import { AuthPages } from '@core/services/auth/auth.models'
import { ExternalRedirectComponent } from '@shared/components/external-redirect/external-redirect.component'

export const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver')

export const MAIN_ROUTES: Routes = [
  { path: '', redirectTo: 'applications', pathMatch: 'full' },
  { path: 'applications', component: ApplicationsComponent },
  {
    path: 'applications/:application',
    component: LoginComponent,
    canActivate: [validApplicationGuard],
  },
  {
    path: 'externalRedirect',
    canActivate: [externalUrlProvider],
    component: ExternalRedirectComponent,
  },
  { path: 'auth/:application', component: CallbackComponent },
  { path: 'logout', component: LogoutComponent },
  { path: AuthPages.NotPermitted, component: ErrorPageComponent },
  { path: '**', component: ErrorPageComponent },
]
