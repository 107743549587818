import { NgModule } from '@angular/core'
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router'

import { externalUrlProvider, MAIN_ROUTES } from './constants'
import { PagesModule } from '@pages/pages.module'

@NgModule({
  exports: [RouterModule],
  imports: [PagesModule, RouterModule.forRoot(MAIN_ROUTES)],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl')
        window.open(externalUrl, '_self')
      },
    },
  ],
})
export class RoutingModule {}
