import { Component } from '@angular/core'

import { Observable } from 'rxjs'
import { AuthService } from '../../services'
import { UserProfile } from '../../services/auth/auth.models'
import { environment } from '@env'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public userProfile$: Observable<UserProfile>
  readonly buildVersion = environment.BUILD_VERSION

  constructor(public authService: AuthService) {
    this.userProfile$ = authService.getUserProfileSubject$()
  }
}
