import { Component } from '@angular/core'

@Component({
  selector: 'app-main-logo',
  templateUrl: './main-logo.component.html',
  styleUrls: ['./main-logo.component.scss'],
})
export class MainLogoComponent {
  public imgPath = '/assets/images/logos/logo-mediacoach.svg'

  constructor() {}
}
