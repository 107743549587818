import { Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpResponse } from '@angular/common/http'

import { filter } from 'rxjs/operators'
import { BehaviorSubject, of, throwError } from 'rxjs'
import { User, UserManager } from 'oidc-client'

import { UserProfile } from './auth.models'
import { clearStorageByPrefix } from '../../utils/main'
import { getRolePermissions, ROLE_TYPE_KEY } from './auth.constants'
import { environment } from '@env'
import { TranslatorService } from '@core/services'
import { AnalyticsService } from '@shared/services/analytics.service'
import { ApplicationName } from '@mediacoach/ui'

const LS_APPLICATION_NAME = 'integrated-app-gate-way-application-name'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userProfileSubject$ = new BehaviorSubject<UserProfile>(null)
  translatedRoles$ = new BehaviorSubject<object>(null)

  mgr: UserManager
  currentUser: User
  loggedIn = false
  isSigningin = false

  set applicationName(applicationName: ApplicationName) {
    window.localStorage.setItem(LS_APPLICATION_NAME, applicationName)
  }

  get applicationName() {
    return window.localStorage.getItem(LS_APPLICATION_NAME) as ApplicationName
  }

  constructor(
    private router: Router,
    public analyticsServices: AnalyticsService,
    private translatorService: TranslatorService,
  ) {
    this.translatorService
      .stream(Object.values(ROLE_TYPE_KEY))
      .subscribe((translatedRoles) => this.translatedRoles$.next(translatedRoles))
    this.userProfileSubject$.subscribe((userProfile) => {
      const { name, surname, roles, language } = userProfile || <UserProfile>{}
      if (language) {
        this.translatorService.use(language, true)
      }
      if (name && surname && roles) {
        userProfile.fullName = `${userProfile.name} ${userProfile.surname}`
        userProfile.rolePermissions = getRolePermissions(userProfile.roles)
        this._getHumanizedRoles(userProfile, this.translatedRoles$.getValue())
      }
    })
    this.translatedRoles$.pipe(filter((value) => value != null)).subscribe((translatedRoles) => {
      this._getHumanizedRoles(this.userProfileSubject$.getValue(), translatedRoles)
    })
  }

  private _getHumanizedRoles(userProfile: UserProfile, translatedRoles: any) {
    const { roles } = userProfile || <UserProfile>{}
    if (roles && translatedRoles) {
      userProfile.humanizedRoles = userProfile.roles
        .map((role) => translatedRoles[ROLE_TYPE_KEY[role]])
        .join(', ')
    }
  }

  private _getSettings() {
    return {
      authority: environment.IDENTITY_SERVER.AUTHORITY_URL,
      client_id: environment.IDENTITY_SERVER.CLIENT_ID[this.applicationName],
      redirect_uri: environment.IDENTITY_SERVER.REDIRECT_URI[this.applicationName],
      post_logout_redirect_uri: environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI,
      response_type: environment.IDENTITY_SERVER.RESPONSE_TYPE[this.applicationName],
      scope: environment.IDENTITY_SERVER.SCOPE[this.applicationName],
      silent_redirect_uri: environment.IDENTITY_SERVER.SILENT_REDIRECT_URI,
      automaticSilentRenew: true,
      loadUserInfo: environment.IDENTITY_SERVER.LOAD_USER_INFO,
    } as any
  }

  getUserManager(): UserManager {
    if (!this.mgr) {
      const settings = this._getSettings()
      this.mgr = new UserManager(settings)
      this.mgr
        .getUser()
        .then((user) => this.setUser(user))
        .catch((err) => this.checkUnauthorizedError(err))
      this.mgr.events.addUserSignedOut(() => this.logOut())
      this.mgr.events.addUserLoaded((user) => this.setUser(user))
      this.mgr.events.addUserUnloaded(() => this.setUser(null))
      this.mgr.events.addAccessTokenExpired(() => this.signinRedirect(this.router.url))
      this.mgr.events.addSilentRenewError(() => this.signinRedirect(this.router.url))
    }
    return this.mgr
  }

  getUserProfileSubject$(): BehaviorSubject<UserProfile> {
    return this.userProfileSubject$
  }

  // USER MANAGING
  setUser(user?: User) {
    this.currentUser = user
    this.loggedIn = !!user
    if (user) {
      this.analyticsServices.trackUser(user.profile.sub)
    }
  }

  checkUnauthorizedError(error: HttpResponse<HttpErrorResponse>, params = { throwError: false }) {
    return params.throwError ? throwError(() => error) : of(<UserProfile>{})
  }

  startSigninMainWindow(routeRequested?: string) {
    this.signinRedirect(routeRequested)
  }

  endSigninMainWindow() {
    this.getUserManager()
      .signinRedirectCallback()
      .then((tokenInfo) => {
        const queryParams = (environment[this.applicationName].queryParams || [])
          .map(({ key, value }) => `${key}=${tokenInfo[value]}`)
          .join('&')
        const externalUrl = `${environment[this.applicationName].REDIRECT_LOGGED_URL}${queryParams && '#' + queryParams}`
        this.router.navigate(['/externalRedirect', { externalUrl }]).then(() => true)
      })
      .catch(() => this.signinRedirect())
  }

  signinRedirect(routeRequested?: string) {
    if (!this.isSigningin) {
      this.isSigningin = true
      this.getUserManager()
        .signinRedirect({ data: routeRequested })
        .then(() => (this.isSigningin = false))
    }
  }

  logOut(url?: string) {
    clearStorageByPrefix(localStorage, 'oidc.')
    window.location.href =
      environment.IDENTITY_SERVER.API_LOGOUT +
      '?id_token_hint=' +
      this.currentUser.id_token +
      '&' +
      environment.IDENTITY_SERVER.TAG_POST_LOGOUT_REDIRECT_URI +
      '=' +
      environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI +
      (url || '')

    this.getUserManager()
      .removeUser()
      .then(() => true)
  }
}
