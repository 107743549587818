<div class="c-error-page">
  <div class="c-error-page__heading p-grid sp-stack--xxl">
    <h1 class="p-col-12">{{ titleInfo.title | translate }}</h1>
    <h2 class="p-col-12">{{ titleInfo.subTitle | translate }}</h2>
  </div>

  <div class="c-error-page__img-container p-grid sp-stack--xxl p-col-12 p-justify-center">
    <img class="p-col c-error-page__img" src="assets/images/flag.svg" alt="" />
  </div>

  <div class="c-error-page__btn p-grid sp-stack--xxl p-col-12 p-justify-center">
    <app-button text="MTR_COMMON_BACK" (click)="navigateBack()"></app-button>
  </div>
</div>
