export enum RoleType {
  Admin = 'Admin',
  Support = 'Support',
  Production = 'Production',
  Technician = 'Technician',
  Sales = 'Sales',
}

export enum AuthPages {
  NotPermitted = 'not-permitted',
}

export interface UserProfile {
  id: string
  name: string
  surname: string
  fullName: string
  language: string
  email: string
  applicationClientId: string
  userId: string
  areConditionsAccepted: boolean
  conditionsAcceptanceDate: string
  roles: RoleType[]
  humanizedRoles: string
  photoPath?: string
  rolePermissions: AuthPages[]
}
