import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'

import { environment } from '@env'
import { thirdPartyApplications } from './valid-application-guard.constants'

const USER_PROFILE_SEPARATOR = 'UserProfile'

export const validApplicationGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const thirdPartyApplicationNames = thirdPartyApplications.map(
    (app) => app.id.split(USER_PROFILE_SEPARATOR)[0],
  )
  const router = inject(Router)

  const { application } = route.params
  if (
    state.url !== environment.NOT_PERMITTED_URL &&
    !thirdPartyApplicationNames.includes(application.trim())
  ) {
    return router.navigate([environment.NOT_PERMITTED_URL])
  }
  return true
}
