import { Component } from '@angular/core'

@Component({
  selector: 'app-external-redirect',
  template: '',
  styles: [''],
})
export class ExternalRedirectComponent {
  constructor() {}
}
