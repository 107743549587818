import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { AuthService } from '@core/services'
import { AnalyticsService } from '@shared/services/analytics.service'

const APPLICATIONS_TRANSLATION = {
  Footovision: 'Vision',
}

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    public analyticsServices: AnalyticsService,
  ) {}

  ngOnInit() {
    const { application } = this.route.snapshot.params
    const applicationName = APPLICATIONS_TRANSLATION[application]
      ? APPLICATIONS_TRANSLATION[application]
      : application
    const category = `access_${applicationName}`
    const action = `navigation_to_${applicationName}`
    const label = `${applicationName} access`

    this.analyticsServices.trackEvent(category, action, label)
    this.authService.applicationName = application
    this.authService.endSigninMainWindow()
  }
}
