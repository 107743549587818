import { Component, OnInit } from '@angular/core'

import { AuthService } from '@core/services'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const { application } = this.route.snapshot.params
    this.authService.applicationName = application

    this.authService.startSigninMainWindow()
  }
}
