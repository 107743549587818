import { environment } from '@env'
import {
  APPLICATION_LIST,
  APPLICATION_MENU_ASSET_PATH,
  ApplicationName,
  ApplicationPlatform,
  getUrlByEnv,
  UserProfileType,
} from '@mediacoach/ui'

const MEDIACOACH_SUITS_PROFILES_TP = [
  UserProfileType.FootovisionUserProfile,
  UserProfileType.SportianAnalysisUserProfile,
]

export const thirdPartyApplications = APPLICATION_LIST.filter(
  (app) => app.thirdParty || MEDIACOACH_SUITS_PROFILES_TP.includes(app.id as UserProfileType),
).map((app) => ({
  ...app,
  name: app.name === ApplicationName.Footovision ? 'Vision' : app.name,
  platform: app.platform ?? ApplicationPlatform.Browser,
  link: app.hasDeeplink ? app.deepLink : getUrlByEnv(app.domain, environment.envType),
  imgFile: `${APPLICATION_MENU_ASSET_PATH}/logo--${app.name.toLowerCase()}.png`,
  hasShadow: app.hasShadow ?? true,
  thirdParty: app.thirdParty ?? false,
  hasPermission: true,
  hasDeeplink: app.hasDeeplink ?? false,
  isVisible: app.isVisible ?? false,
}))
