import { Component } from '@angular/core'

import { APPLICATION_MENU_ASSET_PATH } from '@mediacoach/ui'
import { thirdPartyApplications } from '@core/guards/valid-application-guard.constants'

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ApplicationsComponent {
  DefaultMenuAvatar = `${APPLICATION_MENU_ASSET_PATH}/app-menu--default.png`
  thirdPartyApplications = thirdPartyApplications
}
