import { NgModule } from '@angular/core'

import {
  LoginComponent,
  LogoutComponent,
  CallbackComponent,
  ErrorPageComponent,
  ApplicationsComponent,
} from './components'

import { SharedModule } from '@shared/shared.module'

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    CallbackComponent,
    ErrorPageComponent,
    ApplicationsComponent,
  ],
  imports: [SharedModule],
})
export class PagesModule {}
