import { ApplicationName, EnvType, getUrlByEnv } from '@mediacoach/ui'
import packageJson from '../../package.json'

const MAIN_APP_NAME = 'integratedapps'

const AUTH_DOMAIN = 'id'

export const VARIABLES_BY_ENVIRONMENT = {
  [EnvType.Dv]: {
    [ApplicationName.Wimu]: {
      REDIRECT_LOGGED_URL: 'https://testlaliga-az.wimucloud.com/',
      queryParams: [{ key: 'id_token', value: 'access_token' }],
    },
    [ApplicationName.Footovision]: {
      REDIRECT_LOGGED_URL: 'http://liga.footovision.com/oidc/get-tokens.html',
      queryParams: [],
    },
    [ApplicationName.SportianAnalysis]: {
      REDIRECT_LOGGED_URL: 'https://analysis-dv.mediacoach.es/',
      queryParams: [],
    },
  },
  [EnvType.Ts]: {
    [ApplicationName.Wimu]: {
      REDIRECT_LOGGED_URL: 'https://testlaliga-az.wimucloud.com/',
      queryParams: [{ key: 'id_token', value: 'access_token' }],
    },
    [ApplicationName.Footovision]: {
      REDIRECT_LOGGED_URL: 'https://pre-mediacoachvision.laliga.es/oidc/get-tokens.html',
      queryParams: [],
    },
    [ApplicationName.SportianAnalysis]: {
      REDIRECT_LOGGED_URL: 'https://analysis-dv.mediacoach.es/',
      queryParams: [],
    },
  },
  [EnvType.Qa]: {
    [ApplicationName.Wimu]: {
      REDIRECT_LOGGED_URL: 'https://testlaliga-az.wimucloud.com/',
      queryParams: [{ key: 'id_token', value: 'access_token' }],
    },
    [ApplicationName.Footovision]: {
      REDIRECT_LOGGED_URL: 'https://pre-mediacoachvision.laliga.es/oidc/get-tokens.html',
      queryParams: [],
    },
    [ApplicationName.SportianAnalysis]: {
      REDIRECT_LOGGED_URL: 'https://analysis-qa.mediacoach.es/',
      queryParams: [],
    },

    GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113083612-2',
  },
  [EnvType.Pr]: {
    production: true,

    [ApplicationName.Wimu]: {
      REDIRECT_LOGGED_URL: 'https://mediacoach.wimucloud.com/',
      queryParams: [{ key: 'id_token', value: 'access_token' }],
    },
    [ApplicationName.Footovision]: {
      REDIRECT_LOGGED_URL: 'https://mediacoachvision.laliga.es/oidc/get-tokens.html',
      queryParams: [],
    },
    [ApplicationName.SportianAnalysis]: {
      REDIRECT_LOGGED_URL: 'https://analysis.mediacoach.es/',
      queryParams: [],
    },

    GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113371562-2',
  },
}

const getEnvironmentInfo = (env: EnvType) => {
  const { APP_DOMAIN, IDENTITY_SERVER } = getDomainInfo(env)
  const ORIGIN_DOMAIN = window.location.origin
  return {
    production: false,
    serverMocks: false,

    BUILD_VERSION: packageJson.version,

    APP_DOMAIN,

    ORIGIN_DOMAIN,

    API_KEY_SECURE: 'Bearer',
    GENERAL_DEBOUNCE_TIME: 200,

    // FRONT URLS
    REDIRECT_APPLICATIONS_URL: '/applications',
    NOT_PERMITTED_URL: '/not-permitted',

    [ApplicationName.Wimu]: {
      REDIRECT_LOGGED_URL: 'https://testmediacoach.wimucloud.com/',
      queryParams: [{ key: 'id_token', value: 'access_token' }],
    },
    [ApplicationName.Footovision]: {
      REDIRECT_LOGGED_URL: 'http://liga.footovision.com/oidc/get-tokens.html',
      queryParams: [],
    },
    [ApplicationName.SportianAnalysis]: {
      REDIRECT_LOGGED_URL: 'https://analysis.mediacoach.es/',
      queryParams: [],
    },

    I18N_LANGS: {
      es: { locale: 'es-ES', i18nLabel: 'MTR_COMMON_LANG_ES' },
      en: { locale: 'en-GB', i18nLabel: 'MTR_COMMON_LANG_EN' },
    },

    GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-108719301-2',

    IDENTITY_SERVER: {
      API_LOGOUT: `${IDENTITY_SERVER}/connect/endsession`,
      API_TOKEN: `${IDENTITY_SERVER}/connect/token`,
      AUTHORITY_URL: IDENTITY_SERVER,
      REDIRECT_URI: {
        [ApplicationName.Wimu]: `${ORIGIN_DOMAIN}/auth/${ApplicationName.Wimu}`,
        [ApplicationName.Footovision]: `${ORIGIN_DOMAIN}/auth/${ApplicationName.Footovision}`,
        [ApplicationName.SportianAnalysis]: `${ORIGIN_DOMAIN}/auth/${ApplicationName.SportianAnalysis}`,
      },
      REGISTER_ACCOUNT_URL: `${IDENTITY_SERVER}/Account/Register`,
      POST_LOGOUT_REDIRECT_URI: APP_DOMAIN,
      TAG_POST_LOGOUT_REDIRECT_URI: 'post_logout_redirect_uri',
      SILENT_REDIRECT_URI: `${ORIGIN_DOMAIN}/silent`,
      ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME: 4,
      LOAD_USER_INFO: true,
      RESPONSE_TYPE: {
        [ApplicationName.Wimu]: 'token id_token',
        [ApplicationName.Footovision]: 'code',
        [ApplicationName.SportianAnalysis]: 'code',
      },
      SCOPE: {
        [ApplicationName.Wimu]: 'openid profile wimu-api-accountmanager',
        [ApplicationName.Footovision]: 'openid visionprofile streams-vod',
        [ApplicationName.SportianAnalysis]: 'openid sportiananalysisprofile streams-vod',
      },
      CLIENT_ID: {
        [ApplicationName.Wimu]: '39f134d0-779a-061a-01c0-e3b6eb42a9cb',
        [ApplicationName.Footovision]: '39f3f4b3-7648-925b-ac2b-009b0c429895',
        [ApplicationName.SportianAnalysis]: '5ed37d8c-afb1-4616-aad8-46e0da7b68db',
      },
    },
  }
}

const getDomainInfo = (env: EnvType) => ({
  APP_DOMAIN: getUrlByEnv(`https://${MAIN_APP_NAME}.mediacoach.es`, env),
  IDENTITY_SERVER: getUrlByEnv(`https://${AUTH_DOMAIN}.mediacoach.es`, env),
})

export const getEnvironment = (env: EnvType) => ({
  envType: env,

  ...getEnvironmentInfo(env),

  ...VARIABLES_BY_ENVIRONMENT[env],

  // Keep this comment untouched. Bamboo will replace it with the following
  // environment variables:
  //
  //     BUILD_VERSION, BUILD_NUM, BUILD_DATE, BUILD_BRANCH, BUILD_COMMIT
  //
  // {{ COMPILATION_VARS }}
})
