import { AuthPages, RoleType } from './auth.models'

export const ROLE_TYPE_KEY = {
  [RoleType.Admin]: 'MTR_ROLE_ADMIN',
  [RoleType.Support]: 'MTR_ROLE_SUPPORT',
  [RoleType.Production]: 'MTR_ROLE_PRODUCTION',
  [RoleType.Technician]: 'MTR_ROLE_TECHNICIAN',
  [RoleType.Sales]: 'MTR_ROLE_SALES',
}

export const ROLE_MAPPING = {
  [RoleType.Admin]: {
    permissions: [],
  },
  [RoleType.Support]: {
    permissions: [],
  },
  [RoleType.Production]: {
    permissions: [],
  },
  [RoleType.Technician]: {
    permissions: [],
  },
  [RoleType.Sales]: {
    permissions: [],
  },
}

export const getRolePermissions = (roles: RoleType[]): AuthPages[] =>
  Array.from(
    new Set(
      roles
        .map((role) => (ROLE_MAPPING[role] || <{ permissions: AuthPages[] }>{}).permissions)
        .reduce((arr, permissions) => [...arr, ...(permissions || [])], []),
    ),
  )
