import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'

import { PAGE_MAPPING } from './error-page.constants'

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  public titleInfo: { title: string; subTitle: string }

  constructor(
    private _location: Location,
    private _activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.titleInfo =
      (this._activatedRoute.routeConfig.path != null
        ? PAGE_MAPPING[this._activatedRoute.routeConfig.path]
        : null) || PAGE_MAPPING['default']
  }

  navigateBack() {
    this._location.back()
  }
}
