import { Injectable } from '@angular/core'
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Router } from '@angular/router'

import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { environment } from '@env'
import { ToastService } from '@mediacoach/ui'

@Injectable()
export class AlertsInterceptor implements HttpInterceptor {
  constructor(
    private toasterService: ToastService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((httpEvent) => {
        if (httpEvent instanceof HttpErrorResponse) {
          if (httpEvent.status === 401) {
            this.router.navigate([environment.REDIRECT_APPLICATIONS_URL])
          }
          const errorResponse = httpEvent.error || {}
          const errorResponseString = JSON.stringify(errorResponse)
          this.toasterService.show(
            {
              message:
                errorResponse.Message ||
                (errorResponseString !== '{}' ? errorResponseString : null),
            },
            { type: 'error' },
          )
        }
      }),
    )
  }
}
